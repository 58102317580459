<template>
  <div>
    <!--Entry-->
    <v-card flat class="white">
      <v-card-text>
        <v-form ref="entryForm" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row dense>
              <v-spacer />
              <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addItem()">
                <v-icon small left>fal fa-plus</v-icon>New</v-btn
              >
              <v-col cols="12" sm="12">
                <v-card flat :key="index" v-for="(item, index) in items">
                  <v-container fluid class="pa-0">
                    <v-row dense>
                      <!--Recipient (Applicant)-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          :items="applicantsStore.applicantsValueList"
                          label="Recipient"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_nameDisplay"
                          item-value="id"
                          v-model="item.fk_applicantID"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--1:Type-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[19]"
                          label="Type"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_type"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--2:Documentation-->
                      <v-col cols="12" sm="12" md="3">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[22]"
                          label="Documentation"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_document"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--3:Amount-->
                      <v-col cols="12" sm="12" md="1">
                        <v-text-field
                          v-model="item.d_amount"
                          label="Amount"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--4:Term-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[20]"
                          label="Term"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_period"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--5:Status-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.activeStatus"
                          label="Status"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="item.f_status"
                        />
                      </v-col>

                      <!--6:Annual Income-->
                      <v-col cols="12" sm="12" md="1">
                        <text-display
                          :displayLabel="'Annual Income'"
                          :displayString="annualIncome(item.d_period, item.d_amount)"
                          :displayBold="true"
                        />
                      </v-col>

                      <!--7:Modified/Created-->
                      <v-col cols="12" sm="12" md="5">
                        <span class="text-caption grey--text">
                          Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
	                        Modified: {{ $_formatTimestamp(item.updated_at) }}
                          </span>
                      </v-col>
                    </v-row>

                    <v-divider />
                    <v-divider />
                    <v-divider />
                  </v-container>
                </v-card>
                <v-divider> </v-divider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "incomeStore/getEntryField",
  mutationType: "incomeStore/updateEntryField"
});

export default {
  name: "IncomeEdit",
  components: {
    TextDisplay
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      incomeStore: state => state.incomeStore,
      valueListsStore: state => state.valueListsStore,
      applicantsStore: state => state.applicantsStore
    }),
    ...mapFields(["show", "valid", "mode"]),
    ...mapMultiRowFields("incomeStore", ["entry.items"])
  },
  data() {
    return {
      tab: 0
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("incomeStore/resetEntry");
    },

    addItem() {
      const client = this.$store.getters["clientsStore/getClient"];
      const data = {
        fk_householdID: client.fk_householdID
      };
      this.$store.dispatch("incomeStore/addItem", data);
    },

    annualIncome(period, amount) {
      let setValue = "";
      if (period === "Weekly") {
        setValue = 52;
      } else if (period === "Bi-weekly") {
        setValue = 26;
      } else if (period === "Monthly") {
        setValue = 12;
      } else if (period === "Semi-monthly") {
        setValue = 24;
      } else if (period === "Annually") {
        setValue = 1;
      }
      let newIncome = setValue * amount;
      newIncome = Math.round(newIncome * 100) / 100;
      return this.$_formatMoney(newIncome);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
