<template>
  <div>
    <v-btn rounded outlined @click="openEntry()"
      ><v-icon small left>fal fa-pencil-alt</v-icon>Edit</v-btn
    >

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1480">
      <v-card flat class="white" height="800">
        <v-card-title class="text-h5 secondary--text"
          >Eligibility Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-container fluid grid-list-md>
            <v-row dense>
              <!--Dual Eligibility-->
              <v-col cols="12" sm="12" md="2">
                <v-autocomplete
                  :items="valueListsStore.yesNo"
                  label="Dual Eligibility"
                  placeholder=" "
                  persistent-placeholder
                  item-text="value"
                  item-value="value"
                  v-model="d_dualEligibility"
                  clearable
                  clear-icon="fal fa-times-circle"
                />
              </v-col>

              <v-col cols="12" sm="12" md="10">
                <span class="grey--text text--darken-1 text-caption">Other Eligible Programs</span>
                <v-radio-group dense row class="my-0 py-0">
                  <v-checkbox
                    dense
                    :key="index"
                    v-for="(item, index) in programsStore.programsValueList"
                    v-model="d_otherEligiblePrograms"
                    :label="item.d_name"
                    :value="item.d_name"
                    color="primary"
                    multiple
                    class="pr-2 py-0 my-0"
                  ></v-checkbox>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
              <v-tab>Employment</v-tab>
              <v-tab>School</v-tab>
              <v-tab>Other Income</v-tab>

              <!--Employment-->
              <v-tab-item>
                <employment-entry />
              </v-tab-item>

              <!--School-->
              <v-tab-item>
                <education-entry />
              </v-tab-item>

              <!--Other Income-->
              <v-tab-item>
                <income-entry />
              </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import EmploymentEntry from "@/components/EmploymentEntry";
import EducationEntry from "@/components/EducationEntry";
import IncomeEntry from "@/components/IncomeEntry";

const { mapFields } = createHelpers({
  getterType: "clientsStore/getEntryField",
  mutationType: "clientsStore/updateEntryField"
});

export default {
  name: "ClientEntryEligibility",
  components: {
    EmploymentEntry,
    EducationEntry,
    IncomeEntry
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: state => state.clientsStore,
      valueListsStore: state => state.valueListsStore,
      programsStore: state => state.programsStore
    }),
    ...mapFields(["show", "d_dualEligibility", "d_otherEligiblePrograms"])
  },
  data() {
    return {
      menu: false,
      tab: 0
    };
  },
  methods: {
    async openEntry() {
      const client = this.$store.getters["clientsStore/getClient"];

      const data2 = {
        id: [13, 18, 19, 20, 21, 22]
      };
      await this.$store.dispatch("valueListsStore/items", data2);
      await this.$store.dispatch("programsStore/valueList");
      const data = {
        fk_applicantID: "",
        fk_householdID: client.fk_householdID
      };
      await this.$store.dispatch("clientsStore/entry", client.uuid);
      await this.$store.dispatch("employmentStore/entry", data);
      await this.$store.dispatch("educationStore/entry", data);
      await this.$store.dispatch("incomeStore/entry", data);

      await this.$store.dispatch("applicantsStore/valueList", data);

      this.tab = 0;
      this.mode = 2;
      this.show = true;
    },

    async saveEntry() {
      const client = this.$store.getters["clientsStore/getClient"];
      const data = {
        fk_householdID: client.fk_householdID
      };

      const entry = this.$store.getters["clientsStore/getEntry"];
      await this.$store.dispatch("clientsStore/update", entry);

      const employment = this.$store.getters["employmentStore/getEntry"];
      await this.$store.dispatch("employmentStore/update", employment);

      const education = this.$store.getters["educationStore/getEntry"];
      await this.$store.dispatch("educationStore/update", education);

      const income = this.$store.getters["incomeStore/getEntry"];
      await this.$store.dispatch("incomeStore/update", income);

      await this.$store.dispatch("householdsStore/income", data);

      this.show = false;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
