<template>
  <div>

    <div v-if="progressStore.progress !== true">

      <!--Title Bar-->
      <title-bar :title-value="clientsStore.client.d_nameLast + ', ' +
      clientsStore.client.d_nameFirst" />

      <!--Function Bar-->
      <client-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>

           <v-col cols="12" sm="12" md="9">
             <v-card flat>
              <v-toolbar dense flat color="secondary" dark>
                <v-icon>fal fa-badge-check</v-icon>
              <v-toolbar-title class="pl-2">Eligibility</v-toolbar-title>

              <v-spacer />
                <client-entry-eligibility />
              </v-toolbar>
            </v-card>

            <v-card flat class="blue-grey lighten-5">
              <v-card-text>

                <v-row dense>
                  <v-col cols="12" sm="12" md="4">
                    <text-display :displayLabel="'Dual Eligibility'" :displayString="clientsStore.client.d_dualEligibility" />
                  </v-col>
                  <v-col cols="12" sm="12" md="8">
                    <text-display :displayLabel="'Other Eligibile Programs'" :displayArray="clientsStore.client.d_otherEligiblePrograms" />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-h6 secondary--text font-weight-bold">Household Income</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="3">
                    <text-display :displayLabel="'Total Household Annual Income'" :displayString="$_formatMoney(householdsStore.householdIncome)" :displayBold="true" />
                  </v-col>

                  <v-col cols="12" sm="3">
                    <text-display :displayLabel="'Total Monthly Income'" :displayString="monthlyIncome(householdsStore.householdIncome)" :displayBold="true" />
                  </v-col>

                  <v-col cols="12" sm="2">
                    <text-display :displayLabel="'5% PF'" :displayString="parentFee(householdsStore.householdIncome,.05)" :displayBold="true" />
                  </v-col>

                  <!--<v-col cols="12" sm="2">
                    <text-display :displayLabel="'4% PF'" :displayString="parentFee(householdsStore.householdIncome,.04)" :displayBold="true" />
                  </v-col>-->

                  <v-col cols="12" sm="2">
                    <text-display :displayLabel="'2% PF'" :displayString="parentFee(householdsStore.householdIncome,.02)" :displayBold="true" />
                  </v-col>

                  <!--<v-col cols="12" sm="1">
                    <text-display :displayLabel="'1% PF'" :displayString="parentFee(householdsStore.householdIncome,.01)" :displayBold="true" />
                  </v-col>-->
                </v-row>

                <v-divider></v-divider>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-h6 secondary--text font-weight-bold">Primary Applicant</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-body-1 grey--text text--darken-3">{{clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameFirst : ''}}<span v-if="clientsStore.client.primary_applicant && clientsStore.client.primary_applicant.d_nameMiddle"> {{clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameMiddle : ''}}</span> {{clientsStore.client.primary_applicant ? clientsStore.client.primary_applicant.d_nameLast : ''}}</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">Employment</span>
                    <employment :key="clientsStore.client.id" :section="1" :householdID="clientsStore.client.fk_householdID"/>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">School</span>
                    <education :key="clientsStore.client.id" :section="1" :householdID="clientsStore.client.fk_householdID"/>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12">
                    <span class="text-subtitle-1 secondary--text font-weight-bold">Other Income</span>
                    <income :key="clientsStore.client.id" :section="1" :householdID="clientsStore.client.fk_householdID"/>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <div v-if="clientsStore.client.other_parent">
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="text-h6 secondary--text font-weight-bold">Other Parent/Guardian</span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="text-body-1 grey--text text--darken-3">{{clientsStore.client.other_parent.d_nameFirst}}<span v-if="clientsStore.client.other_parent.d_nameMiddle"> {{clientsStore.client.other_parent.d_nameMiddle}}</span> {{clientsStore.client.other_parent.d_nameLast}}</span>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="text-subtitle-1 secondary--text font-weight-bold">Employment</span>
                      <employment :key="clientsStore.client.id" :section="2" :householdID="clientsStore.client.fk_householdID"/>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="text-subtitle-1 secondary--text font-weight-bold">School</span>
                      <education :key="clientsStore.client.id" :section="2" :householdID="clientsStore.client.fk_householdID"/>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <span class="text-subtitle-1 secondary--text font-weight-bold">Other Income</span>
                      <income :key="clientsStore.client.id" :section="2" :householdID="clientsStore.client.fk_householdID"/>
                    </v-col>
                  </v-row>
                </div>

              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <notes :key="clientsStore.client.id" :noteSection="4" />
          </v-col>

        </v-row>
      </v-container>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ClientFunctionBar from '@/components/ClientFunctionBar';
import Notes from '@/components/Notes';
import TextDisplay from '@/components/TextDisplay';
import ClientEntryEligibility from '@/components/ClientEntryEligibility';
import Employment from '@/components/Employment';
import Education from '@/components/Education';
import Income from '@/components/Income';

export default {
  name: 'ClientEligibility',
  components: {
	  TitleBar,
    ClientFunctionBar,
    Notes,
    TextDisplay,
    ClientEntryEligibility,
    Employment,
    Education,
    Income,
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      clientsStore: (state) => state.clientsStore,
      householdsStore: (state) => state.householdsStore,
      progressStore: (state) => state.progressStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
  },
  created() {
    this.initialize();
  },
  data() {
    return {

    };
  },
  methods: {
	  async initialize() {
      this.$store.dispatch('progressStore/set', true);
      await this.$store.dispatch('clientsStore/read', this.$route.params.clientUUID);

      const client = this.$store.getters['clientsStore/getClient'];
      const data = {
        fk_householdID: client.fk_householdID,
      };
      await this.$store.dispatch('employmentStore/fetch', data);
      await this.$store.dispatch('educationStore/fetch', data);
      await this.$store.dispatch('incomeStore/fetch', data);
      await this.$store.dispatch('householdsStore/income', data);

      this.$store.dispatch('progressStore/set', false);
    },

    monthlyIncome(income) {
      return this.$_formatMoney(income / 12);
    },

    parentFee(income, percent) {
      const monthly = income / 12;
      return this.$_formatMoney(monthly * percent);
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
