<template>
  <div>
    <!--Entry-->
    <v-card flat class="white">
      <v-card-text>
        <v-form ref="entryForm" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row dense>
              <v-spacer />
              <v-btn rounded dark color="green lighten-1" class="mb-2" @click="addItem()">
                <v-icon small left>fal fa-plus</v-icon>New</v-btn
              >
              <v-col cols="12" sm="12">
                <v-card flat :key="index" v-for="(item, index) in items">
                  <v-container fluid class="pa-0">
                    <v-row dense>
                      <!--Employee (Applicant)-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          :items="applicantsStore.applicantsValueList"
                          label="Employee"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_nameDisplay"
                          item-value="id"
                          v-model="item.fk_applicantID"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--1:Employer-->
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model="item.d_employer"
                          label="Employer"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--2:Hours Per Week-->
                      <v-col cols="12" sm="12" md="1">
                        <v-text-field
                          v-model="item.d_hoursPerWeek"
                          label="Weekly Hours"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--3:Documentation-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[13]"
                          label="Documentation"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_document"
                          clearable
                          clear-icon="fal fa-times-circle"
                        />
                      </v-col>

                      <!--4:Income Period-->
                      <v-col cols="12" sm="12" md="2">
                        <v-autocomplete
                          :items="valueListsStore.valueListItems[18]"
                          label="Units"
                          placeholder=" "
                          persistent-placeholder
                          item-text="d_name"
                          item-value="d_name"
                          v-model="item.d_incomePeriod"
                          clearable
                          clear-icon="fal fa-times-circle"
                          @input="setFactor(index, item.d_incomePeriod, item.d_hoursPerWeek)"
                        />
                      </v-col>

                      <!--5:Income-->
                      <v-col cols="12" sm="12" md="1">
                        <v-text-field
                          v-model="item.d_income"
                          label="Amount"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--6:Factor-->
                      <v-col cols="12" sm="12" md="1">
                        <v-text-field
                          v-model="item.d_factor"
                          label="Factor"
                          placeholder=" "
                          persistent-placeholder
                        >
                        </v-text-field>
                      </v-col>

                      <!--7:Status-->
                      <v-col cols="12" sm="12" md="1">
                        <v-autocomplete
                          :items="valueListsStore.activeStatus"
                          label="Status"
                          placeholder=" "
                          persistent-placeholder
                          item-text="value"
                          item-value="id"
                          v-model="item.f_status"
                        />
                      </v-col>

                      <!--8:Annual Income-->
                      <v-col cols="12" sm="12" md="1">
                        <text-display
                          :displayLabel="'Annual Income'"
                          :displayString="annualIncome(item.d_factor, item.d_income)"
                          :displayBold="true"
                        />
                      </v-col>

                      <!--9:Modified/Created-->
                      <v-col cols="12" sm="12" md="5">
                        <span class="text-caption grey--text">
                          Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
	                        Modified: {{ $_formatTimestamp(item.updated_at) }}
                          </span>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-divider />
                    <v-divider />
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "employmentStore/getEntryField",
  mutationType: "employmentStore/updateEntryField"
});

export default {
  name: "EmploymentEdit",
  components: {
    TextDisplay
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      employmentStore: state => state.employmentStore,
      valueListsStore: state => state.valueListsStore,
      applicantsStore: state => state.applicantsStore
    }),
    ...mapFields(["show", "valid", "mode"]),
    ...mapMultiRowFields("employmentStore", ["entry.items"])
  },
  data() {
    return {
      tab: 0
    };
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("employmentStore/resetEntry");
    },

    addItem() {
      const client = this.$store.getters["clientsStore/getClient"];
      const data = {
        fk_householdID: client.fk_householdID
      };
      this.$store.dispatch("employmentStore/addItem", data);
    },

    annualIncome(factor, income) {
      let newIncome = factor * income;
      newIncome = Math.round(newIncome * 100) / 100;
      return this.$_formatMoney(newIncome);
    },

    setFactor(index, value, hours) {
      let setValue = "";
      if (value === "Hourly") {
        setValue = hours * 52;
      } else if (value === "Weekly") {
        setValue = 52;
      } else if (value === "Bi-weekly") {
        setValue = 26;
      } else if (value === "Monthly") {
        setValue = 12;
      } else if (value === "Semi-monthly") {
        setValue = 24;
      } else if (value === "Annually") {
        setValue = 1;
      }
      const data = {
        index,
        value: setValue
      };
      this.$store.dispatch("employmentStore/setFactor", data);
    },

    displayName(firstName, lastName) {
      return `${firstName} ${lastName}`;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
