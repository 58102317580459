<template>
  <div>

    <!--Table-->
    <v-simple-table
      v-if="incomeStore.income && incomeStore.income.length > 0 && $_checkSection(incomeStore.income)"
      :fixed-header="true"
      class="py-0 my-0 blue-grey lighten-5"
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Type</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Documentation</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Amount</th>
            <th class="text-left grey--text text--darken-1 blue-grey lighten-5">Term</th>
            <th class="text-right grey--text text--darken-1 blue-grey lighten-5">Annual Income</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in incomeStore.income" v-if="section === 1 && item.applicant.f_type === 1 || section === 2 && item.applicant.f_type === 2" :key="item.id">
          <tr v-bind:style="index > 0 ? 'border-top: solid thin #dae1e7;' : ''">
            <td style="border:none" class="text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_type }}</td>
            <td style="border:none" class="text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_document }}</td>
            <td style="border:none" class="text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ $_formatMoney(item.d_amount) }}</td>
            <td style="border:none" class="text-left" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ item.d_period }}</td>
            <td style="border:none" class="text-right" :class="item.f_status === 1 ? 'light-blue--text text--darken-4': 'error--text'">{{ annualIncome(item.d_period, item.d_amount) }}</td>
          </tr>
          <tr>
            <td colspan="7" style="vertical-align: text-top; height:5px"><span class="text-caption grey--text"
                >Created: {{ $_formatTimestamp(item.created_at) }} &#8226;
                Modified: {{ $_formatTimestamp(item.updated_at) }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-list v-else class="py-0 blue-grey lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="error--text text-subtitle-2 text-xs-center">No Other Income
            Exists</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';

export default {
  name: 'Income',
  mixins: [mixin],
  computed: {
    ...mapState({
      incomeStore: (state) => state.incomeStore,
    }),
  },
  props: {
    /* Primary = 1, Other Parent = 2 */
    section: {
      type: Number,
      required: false,
    },
    householdID: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      progress: true,
    };
  },
  methods: {

    annualIncome(period, amount) {
      let setValue = '';
      if (period === 'Weekly') {
        setValue = 52;
      } else if (period === 'Bi-weekly') {
        setValue = 26;
      } else if (period === 'Monthly') {
        setValue = 12;
      } else if (period === 'Semi-monthly') {
        setValue = 24;
      } else if (period === 'Annually') {
        setValue = 1;
      }
      return this.$_formatMoney(setValue * amount);
    },

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
